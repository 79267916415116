<template>
	<div>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError
				@finish="onSubmit" :labelCol="{span: 4}" :wrapperCol="{span: 14}">
				<a-form-item label="标题">
					会员手册
				</a-form-item>

				<a-form-item name="content" :rules="[{ required: true, message: '必填项不允许为空' }]" label="描述图文">
					<div style="position: relative;z-index: 99;">
						<Editor v-model:value="modelRef.content" :configSetting="{}"></Editor>
					</div>
				</a-form-item>

				<a-row>
					<a-col :offset="6">
						<a-button type="primary" html-type="submit">提交</a-button>
					</a-col>
				</a-row>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Editor from '@/components/editor/editor';
	import {
		getDict,
		editConfig
	} from "@/service/modules/config";
	export default {
		components: {
			Editor
		},
		data() {
			return {
				loading: false,
				modelRef: {}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				postData.dataValue = JSON.stringify({content: postData.content});
				delete postData.content;
				this.loading = true;
				let ret = await editConfig(postData);
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('更新成功');
				}
			},
			async getData() {
				this.loading = true;
				let ret = await getDict({
					key: 'userManual'
				})
				this.loading = false;
				if (ret.code === 200) {
					this.modelRef = ret.data;
					console.log(JSON.parse(ret.data.dataValue), 111111111)
					this.modelRef.content = JSON.parse(ret.data.dataValue).content;
				}
			},
		}
	}
</script>

<style>
</style>